// admin
export const SET_USER_DATA = "setUser";
export const CLEAR_REDUX_STATE = "ClearReduxState";
export const IS_VERIFIED = 'IsVerifiedAction';

// seizer
export const SET_SEIZER_LIST = "SET_SEIZER_LIST";
export const REMOVE_ONE_SEIZER = "REMOVE_ONE_SEIZER";
export const ADD_ONE_SEIZER = "ADD_ONE_SEIZER";

// email
export const SET_TEXT_FORMATS = "SET_TEXT_FORMATS";

// uploadFiles
export const SET_FILE_LIST = "SET_FILE_LIST";
export const ADD_ONE_UPLOAD_FILE = "ADD_ONE_UPLOAD_FILE";
export const REMOVE_ONE_UPLOAD_FILE = "REMOVE_ONE_UPLOAD_FILE";

// Finance
export const ADD_ONE_FINANCE = "ADD_ONE_FINANCE";
export const REMOVE_ONE_FINANCE = "REMOVE_ONE_FINANCE";
export const SET_FINANCE_LIST = "SET_FINANCE_LIST";

// AccessToken
export const ADD_ACCESS_TOKEN = 'AddAccessToken';

// socket
export const INITIALIZE_SOCKET = 'InitializeSocket';
export const INITIALIZE_QR_CONNECTION = 'INITIALIZE_QR_CONNECTION';
export const CLOSE_QR_CONNECTION = 'CLOSE_QR_CONNECTION';
export const LOGIN_WITH_UID_TYPE = 'LoginWithUid';
export const SOCKET_NOTIFICATION_INITIALIZE = 'NotificationSocketInitialize';
export const ADD_NOTIFICATION = 'AddNotification';
export const REMOVE_NOTIFICATION = 'RemoveNotification';
export const CLEAR_NOTIFICATION = 'ClearNotification';

// Total Record And Delete Count
export const TOTAL_RECORD = 'TotalRecordResponse';
export const UPDATE_VEHICLE_COUNT = 'UpdateVehicleCount';
export const UPDATE_DELETE_VEHICLE_COUNT = 'UpdateVehicleDeleteCount';

// Web Refresh
export const WEB_REFRESH = 'WEB_REFRESH';
export const SET_REQUESTS = 'SET_REQUESTS';

// User
export const SET_USERS = "SET_USERS";

// vehicle
export const SET_VEHICLE = 'SET_VEHICLE';