import { useEffect, useState } from "react";
import { AiFillAndroid, AiOutlineSearch } from 'react-icons/ai';
import { BiBuildings, BiCoinStack, BiSolidBell } from 'react-icons/bi';
import Loader from "../common.component/loader";

import { MdChevronRight, MdClose, MdHistory, MdLink, MdOutlineAccountCircle, MdOutlineCloudUpload, MdOutlineDashboard, MdOutlineMyLocation, MdOutlineUploadFile } from "react-icons/md";

import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Drawer,
    Navbar,
    Typography
} from "@material-tailwind/react";
import { cloneDeep, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import constantAll, { storageKey } from "../../common/constant/constant.all";
import utils from "../../common/utils";
import { clearReduxState, setVehicle } from "../../reducer/state/action";
import ApiService, { ApiUrls } from "../../service/service.client";
import storageService from "../../service/storageService";
import MenuOptions from '../common.component/menuOptions';
import { toastAction } from "./toastify";

const SideNavbar = ({ children }) => {
    const { user } = useSelector((state) => state.stateReducer);
    const { tenantId: { urlPath } } = user;

    const profileMenuItems = [
        {
            label: "My Profile",
            value: 'profile'
        },
        {
            label: "Setting",
            value: 'setting'
        },
        {
            label: "Sign Out",
            value: 'logout'
        },
    ];

    const searchOptions = [
        {
            label: "Reg No",
            value: 0
        },
        {
            label: "Chassis No",
            value: 1
        },
        {
            label: "Engine No",
            value: 2
        }
    ];

    const navOption = [
        { to: `/${urlPath}`, icons: <AiOutlineSearch size={20} className="text-gray-700" />, title: 'Search' },
        { to: `/${urlPath}/dashboard`, icons: <MdOutlineDashboard size={20} className="text-gray-700" />, title: 'Dashboard' },
        { to: `/${urlPath}/file-upload/direct`, icons: <MdOutlineCloudUpload size={20} className="text-gray-700" />, title: 'Direct Upload' },
        { to: `/${urlPath}/file-upload`, icons: <MdOutlineUploadFile size={20} className="text-gray-700" />, title: 'File Upload' },
        { to: `/${urlPath}/user/accounts`, icons: <MdOutlineAccountCircle size={20} className="text-gray-700" />, title: 'User Management' },
        { to: `/${urlPath}/financeAccount`, icons: <BiBuildings size={20} className="text-gray-700" />, title: 'Finance Management' },
        { to: `/${urlPath}/connection`, icons: <MdLink size={20} className="text-gray-700" />, title: 'Connection' },
        { to: `/${urlPath}/user/batch`, icons: <BiCoinStack size={20} className="text-gray-700" />, title: 'User Setting' },
        { to: `/${urlPath}/user/location`, icons: <MdOutlineMyLocation size={20} className="text-gray-700" />, title: 'User Location' },
        { to: `/${urlPath}/user-histories`, icons: <MdHistory size={20} className="text-gray-700" />, title: 'User Histories' },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectSearchOption, setSelectSearchOption] = useState(searchOptions[0]);
    const [selectedPage, setSelectedPage] = useState(navOption[0]);
    const [searchText, setSearchText] = useState('');
    const [selectedSeries, setSelectedSeries] = useState({ label: 'ALL', value: 'ALL' });
    const [recentFiles, setRecentFiles] = useState([]);

    // Menu Stats
    const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isOpenSearchOptionMenu, setIsOpenSearchOptionMenu] = useState(false);
    const [isOpenSeries, setIsOpenSeries] = useState(false);
    const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
    const [isOpenSendAndroid, setIsOpenSendAndroid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (searchText) {
            searchOnVehicle();
        }
    }, [searchText]);

    const searchOnVehicle = async () => {
        let search = '';
        if (selectSearchOption.value === 0 && searchText.length === 4) {
            search = cloneDeep(searchText);
        } else if (searchText.length === 5) {
            search = cloneDeep(searchText);
        }

        if (search) {
            setSearchText('');
            searchVehicleFromServer(search);
        }
    }

    const searchVehicleFromServer = async (query) => {
        let url = ApiUrls.searchVehicleFromOwnDB;
        if (utils.isSharedData(user.tenantId)) {
            url = ApiUrls.searchVehicleFromOwnDBShared;
        }

        const { data } = await ApiService.get(utils.formatString(url, query, selectSearchOption.value, 0));
        if (data) {
            if (data.vehicles.length === 0) {
                toastAction.info('Searched vehicle not found.');
                return;
            }

            dispatch(setVehicle({
                vehicles: data.vehicles,
                mode: selectSearchOption.value,
                series: selectedSeries.value
            }));

            if (selectedPage.to !== `/${urlPath}`) {
                setIsOpenSearchDialog(true);
            }
        }
    }

    const onLogOut = () => {
        storageService.removeJsonItem(storageKey.accessToken);
        dispatch(clearReduxState());
    }

    const onSelectOption = (option) => {
        setSelectSearchOption(option);
    }

    const onProfileOptionClick = (option) => {
        switch (option.value) {
            case 'profile':
                navigate(`/${urlPath}/profile`);
                break;
            case 'setting':
                ///
                break;
            case 'logout':
                onLogOut();
                break;
        }
    }

    const onPageChange = (page) => {
        setSelectedPage(page);

        navigate(page.to);
        setIsOpenDrawer(false);
    }

    const getSeriesOptions = () => {
        const seriesList = map(constantAll.vehicleSeries, (series) => ({
            label: series,
            value: series
        }));

        return seriesList;
    }

    const onSeriesSelect = (val) => {
        setSelectedSeries(val);
        setIsOpenSeries(false);
    }

    const onSearchChange = (value) => {
        if (!value) {
            setSearchText('');
        }

        if (selectSearchOption.value === 0) {
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                setSearchText(value);
            }
        } else {
            setSearchText(value);
        }
    }

    const onOpenSearchPage = () => {
        setSelectedPage(`/${urlPath}`);
        navigate(`/${urlPath}`);
        setIsOpenSearchDialog(false);
    }

    const getRecentFiles = async () => {
        const { data } = await ApiService.get(ApiUrls.getRecentUploadedFiles);

        if (data && data?.recentFiles && data?.recentFiles?.length === 0) {
            toastAction.info('Recently uploaded files not found.');
            return;
        }

        setRecentFiles(data?.recentFiles);
        setIsOpenSendAndroid(true);
    }

    const onSendToAndroidClick = async () => {
        setIsLoading(true);
        const { data } = await ApiService.get(ApiUrls.sendToAndroid);
        if (data) {
            toastAction.info('Files sent to android.');
        }

        setIsLoading(false);
        setIsOpenSendAndroid(false);
    }

    return (
        <div className="flex">
            {/* Drawer Absolute */}
            <div className={utils.classNames(
                'bg-gray-400 backdrop-filter content-center inset-0 backdrop-blur-sm bg-opacity-40 fixed w-screen h-screen z-50 flex',
                isOpenDrawer ? 'visible' : 'hidden'
            )}>
                <div className={utils.classNames(
                    'flex bg-white shadow-md w-96 flex-col h-full'
                )}>
                    <div className="flex relative flex-row bg-cyan-50 shadow-sm px-5 py-3 gap-x-4 items-center">
                        <Avatar src={user?.tenantId?.logo} alt="avatar" variant="rounded" />
                        <div className="flex flex-col">
                            <h1 className={`text-black origin-left font-medium font-newsreader text-base duration-200`}>
                                {user?.tenantId?.displayName || 'Smart Seizing V2'}
                            </h1>
                            <Typography className="text-sm text-gray-500">{user?.userName}</Typography>
                        </div>

                        <div onClick={() => setIsOpenDrawer(false)} className="p-2 rounded text-blue-gray-700 cursor-pointer hover:bg-cyan-100 hover:shadow absolute top-2 right-3 z-30">
                            <MdClose size={20} />
                        </div>
                    </div>
                    <ul className="p-4">
                        {navOption.map((Menu, index) => (
                            <li
                                onClick={() => onPageChange(Menu)}
                                key={index}
                                className={`${selectedPage.to === Menu.to && 'bg-gradient-to-r from-blue-gray-100'} flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
                                ${Menu.gap ? "mt-9" : "mt-2"} ${index === 0 && "bg-light-white"} `}
                            >
                                <div className="flex flex-row justify-center items-center space-x-4">
                                    {Menu.icons}
                                    <span className={`origin-left text-gray-700 font-newsreader mt-1 duration-200`}>
                                        {Menu.title}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div onClick={() => setIsOpenDrawer(false)} className="flex flex-grow bg-transparent">

                </div>
            </div>
            {/* Drawer Absolute */}

            <div className="h-screen w-screen flex flex-col">
                <Navbar className="sticky top-0 shadow mb-0 bg-white z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-4">
                    <div className="w-full flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center space-x-3">
                            <div onClick={() => setIsOpenDrawer(true)} className="p-2 rounded cursor-pointer hover:bg-blue-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-teal-900">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                                </svg>
                            </div>
                            <Typography variant='h6' className="font-newsreader pt-1" color="black">{user?.tenantId?.displayName}</Typography>
                            <Chip
                                variant="ghost"
                                value={selectedPage.title}
                                color="teal"
                                size="sm"
                                onClick={() => setIsOpenDrawer(true)}
                                className="font-newsreader cursor-pointer"
                            />
                        </div>

                        <div className="flex flex-row items-center space-x-3">
                            <Button onClick={getRecentFiles} className="outline-none text-green-400 border-green-400 flex gap-3 justify-center items-center" size='sm' variant='outlined'>
                                Send to android
                                <AiFillAndroid size={20} />
                            </Button>
                            <div className="flex flex-row items-center rounded-lg border border-cyan-400 py-1.5 px-3">
                                <input value={searchText} onChange={(e) => onSearchChange(e.target.value)} className="text-gray-600 outline-none w-36 font-newsreader" placeholder={`Search by ${selectSearchOption.label}`} />
                                <AiOutlineSearch className="text-gray-600" />
                            </div>
                            <hr className="rotate-90 w-8 text-black" />
                            <MenuOptions
                                onOptionSelect={onSeriesSelect}
                                isOpen={isOpenSeries}
                                setIsOpenHandler={setIsOpenSeries}
                                allowHover={false}
                                isAllowSearch={true}
                                menuOptions={getSeriesOptions()}
                                menuHandlerComponent={
                                    <div className="flex flex-row items-center cursor-pointer space-x-1">
                                        <Typography className="cursor-pointer" variant="small" color="black">{selectedSeries.label}</Typography>
                                        <MdChevronRight className={utils.classNames(
                                            'text-black',
                                            isOpenSeries
                                                ? 'rotate-90'
                                                : 'rotate-0'
                                        )} />
                                    </div>
                                }
                            />
                            <MenuOptions
                                onOptionSelect={onSelectOption}
                                isOpen={isOpenSearchOptionMenu}
                                setIsOpenHandler={setIsOpenSearchOptionMenu}
                                allowHover={true}
                                menuOptions={searchOptions}
                                menuHandlerComponent={
                                    <div className="flex flex-row items-center cursor-pointer space-x-1">
                                        <Typography className="cursor-pointer" variant="small" color="black">{selectSearchOption.label}</Typography>
                                        <MdChevronRight className={utils.classNames(
                                            'text-black',
                                            isOpenSearchOptionMenu
                                                ? 'rotate-90'
                                                : 'rotate-0'
                                        )} />
                                    </div>
                                }
                            />
                            <BiSolidBell size={22} className="text-gray-400 cursor-pointer" />
                            <MenuOptions
                                onOptionSelect={onProfileOptionClick}
                                isOpen={isOpenProfileMenu}
                                allowHover={true}
                                menuOptions={profileMenuItems}
                                setIsOpenHandler={setIsOpenProfileMenu}
                                menuHandlerComponent={
                                    <Button
                                        variant="text"
                                        color="blue-gray"
                                        className="flex items-center rounded-full py-0.5 pr-2 pl-0.5"
                                    >
                                        <Avatar
                                            variant="circular"
                                            size="sm"
                                            alt="tania andrew"
                                            className="border border-gray-900 p-0.5"
                                            src={user?.profileImage}
                                        />
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                </Navbar>
                {children}

                {/* Dialogs */}
                <Dialog open={isOpenSearchDialog} handler={setIsOpenSearchDialog}>
                    <DialogHeader>Found vehicles.</DialogHeader>
                    <DialogBody>
                        You are on different page. do you want to open search page to see list of found vehicle(s).
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={() => setIsOpenSearchDialog(false)}
                            className="mr-1 outline-none"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button variant="outlined" color="green" className="outline-none" onClick={() => onOpenSearchPage()}>
                            <span>Open</span>
                        </Button>
                    </DialogFooter>
                </Dialog>

                <Drawer placement='right' size={360} open={isOpenSendAndroid} onClose={setIsOpenSendAndroid}>
                    <div
                        style={{ height: window.screen.height * 0.60 }}
                        className='bg-white flex flex-col'>
                        <div className='flex flex-row w-full justify-between items-center p-3'>
                            <small className='font-newsreader font-semibold text-sm text-gray-600'>RECENT FILES</small>
                            <MdClose onClick={() => setIsOpenSendAndroid(false)} className='text-gray-600 cursor-pointer' size={18} />
                        </div>
                        <hr />
                        <div className='flex px-2 overflow-y-auto flex-col w-full h-5/6'>
                            {map(recentFiles, (recentFile, rIndex) => (
                                <div key={rIndex} className='w-full p-2 flex flex-col space-y-1'>
                                    <small className='text-gray-700 text-sm font-newsreader font-semibold'>{recentFile?.fileName}</small>
                                    <small className='text-gray-700 text-sm font-newsreader font-semibold'>{recentFile?.finance}</small>
                                    <small className='text-gray-400 text-xs font-newsreader font-semibold'>{utils.formatDate(recentFile?.createdAt, 'DD-MMMM-yyyy HH:mm')}</small>
                                    <hr />
                                </div>
                            ))}
                        </div>

                        <div className='flex p-2 absolute bottom-2 w-full border-t'>
                            <Button disabled={isLoading} size='sm' onClick={onSendToAndroidClick} variant='filled' className='w-full flex gap-3 justify-center items-center'>
                                Send to Android
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </div>

            {
                isLoading
                    ? <Loader />
                    : null
            }
        </div>
    )
}

export default SideNavbar;
