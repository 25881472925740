import * as _ from 'lodash';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Routes as BrowserRoutes, Navigate, Route } from 'react-router-dom';
import LoadingComponent from '../component/common.component/loading';
import Layout from '../component/common.component/sideNavBar';

const PageNotFound = lazy(() => import('../component/common.component/pageNotFound'));
const Login = lazy(() => import('../component/auth/login'));
const UploadData = lazy(() => import('../component/uploadData/uploadData'));
const SeizerProfile = lazy(() => import('../component/seizerProfile/profile'));
const UserManagement = lazy(() => import('../component/UserManagement/users'));
const FinanceProfile = lazy(() => import('../component/finance/finance'));
const SeizerLocation = lazy(() => import('../component/location/seizerLocation'));
const UserLocation = lazy(() => import('../component/location/userLocation'));
const Home = lazy(() => import('../component/home/home'));
const PrivacyPolicy = lazy(() => import('../component/privacyPolicy/privacyPolicy'));
const FileUpload = lazy(() => import('../component/fileUpload/uploads'));
const Dashboard = lazy(() => import('../component/dashboard/dashboard'));
const UserBatchManagement = lazy(() => import('../component/UserManagement/userBatchManagement'));
const Connection = lazy(() => import('../component/connection/connection'));
const FormatUpload = lazy(() => import('../component/fileUpload/formatUpload'));
const UserProfile = lazy(() => import('../component/UserManagement/userProfile'));
const ConnectionDetail = lazy(() => import('../component/connection/connectionDetail'));
const UserHistoryPage = lazy(() => import('../component/userHistory/userHistories'));
const UserHistory = lazy(() => import('../component/userHistory/userHistory'));

const UnProtectedRoutes = [
    { path: '/login', exact: true, name: 'Login', component: Login },
];

const ProtectedRoutes = [
    { path: '/:urlPath', exact: true, name: 'Home', component: Home },
    { path: '/:urlPath/profile', exact: true, name: 'Profile', component: UserProfile },
    { path: ':urlPath/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: ':urlPath/accounts', exact: true, name: 'SeizerProfile', component: SeizerProfile },
    { path: ':urlPath/user/accounts', exact: true, name: 'userProfile', component: UserManagement },
    { path: ':urlPath/user/batch', exact: true, name: 'batch', component: UserBatchManagement },
    { path: ':urlPath/location', exact: true, name: 'SeizerLocation', component: SeizerLocation },
    { path: ':urlPath/user/location', exact: true, name: 'SeizerLocation', component: UserLocation },
    { path: ':urlPath/financeAccount', exact: true, name: 'FinanceProfile', component: FinanceProfile },
    { path: ':urlPath/file-upload', exact: true, name: 'FileUpload', component: FileUpload },
    { path: ':urlPath/file-upload/format', exact: true, name: 'FormatUpload', component: FormatUpload },
    { path: ':urlPath/file-upload/direct', exact: true, name: 'UploadData', component: UploadData },
    { path: ':urlPath/connection', exact: true, name: 'Connection', component: Connection },
    { path: ':urlPath/connection/:detail/:id', exact: true, name: 'ConnectionDetail', component: ConnectionDetail },
    { path: ':urlPath/user-histories', exact: true, name: 'UserHistory', component: UserHistoryPage },
    { path: ':urlPath/histories/:userName/:phoneNumber', exact: true, name: 'UserHistory', component: UserHistory },
];

const HybridRoutes = [
    { path: '*', exact: false, name: 'Page Not Found', component: PageNotFound },
    { path: '/privacy-policy', name: 'Privacy Policy', component: PrivacyPolicy }
];

export default function Routes() {
    const userDetail = useSelector((state) => state.stateReducer.user);
    return (
        <Suspense fallback={<LoadingComponent />}>
            <BrowserRoutes>
                {
                    _.map(UnProtectedRoutes, (route, index) => {
                        const { component: Component } = route;
                        return <Route key={`UnProtectedRoute${index}`}
                            path={route.path}
                            element={userDetail ? <Navigate to={`/${userDetail?.tenantId?.urlPath || ''}`} /> : <Component />} />;
                    })
                }
                {
                    _.map(ProtectedRoutes, (route, index) => {
                        const { component: Component } = route;
                        return (
                            <Route key={`ProtectedRoute${index}`} path={route.path}
                                element={userDetail ? <Layout><Component /></Layout>
                                    : <Navigate to='/login' />}
                            />);
                    })
                }
                {
                    _.map(HybridRoutes, (route, index) => {
                        const { component: Component } = route;
                        return (
                            <Route key={`HybridRoute${index}`} path={route.path}
                                element={<Component />}
                            />);
                    })
                }
            </BrowserRoutes>
        </Suspense>
    );
}