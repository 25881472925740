import { Input, Menu, MenuHandler, MenuItem, MenuList, Typography } from "@material-tailwind/react";
import { filter, map } from "lodash";
import { useState } from "react";

export default function MenuOptions({
    menuHandlerComponent,
    isOpen,
    setIsOpenHandler,
    onOptionSelect,
    menuOptions,
    allowHover = true,
    isAllowSearch = false,
}) {
    const [search, setSearch] = useState('');

    const filterList = () => {
        if (search) {
            return filter(menuOptions, (option) => option.label.toLowerCase().includes(search.toLocaleLowerCase().trim()));
        }

        return menuOptions;
    }

    return (
        <Menu
            dismiss={{
                itemPress: !isAllowSearch,
            }}
            open={isOpen}
            handler={setIsOpenHandler}
            allowHover={allowHover}
            placement="bottom-end">
            <MenuHandler>
                {menuHandlerComponent}
            </MenuHandler>
            <MenuList className="p-1 max-h-72">
                {
                    isAllowSearch
                        ? <Input
                            className='sticky top-0'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            label="Search"
                            containerProps={{
                                className: "mb-4",
                            }}
                        /> : null
                }
                {map(filterList(menuOptions), ({ label, value, icon }, key) => {
                    return (
                        <MenuItem
                            key={key}
                            onClick={() => onOptionSelect({ label, value, icon })}
                            className={`flex items-center gap-2 rounded`}
                        >
                            <Typography
                                as="span"
                                variant="small"
                                className="font-normal"
                            >
                                {label}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
}