const Enums = {
    statusCode: {
        ok: 200,
        internalServerError: 500,
        badRequest: 400,
        notFound: 404,
        unAuth: 403,
        validationError: 422,
    }
}

export default Enums;