import * as _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { toastAction } from '../component/common.component/toastify';

class UtilsHelper {
    toBase64(value) {
        if (value) {
            return Buffer.from(value, 'utf8').toString('base64');
        }
        return value;
    }

    fromBase64(value) {
        if (value) {
            return Buffer.from(value, 'base64').toString();
        }
        return value;
    }

    isValidNumber(numberValue) {
        const reg = new RegExp('^[0-9]+$');
        return reg.test(numberValue);
    }

    formatString(stringToFormat, ...args) {
        const newString = stringToFormat.replace(/{(\d+)}/g, (match, index) => {
            return args[index];
        });
        return newString;
    }

    displayDate(value) {
        return moment(moment.utc(value)).local().format('MM/DD/YYYY');
    }

    displayDateTime(value) {
        if (value && moment(value).year() > 1971) {
            return moment(moment.utc(value)).local().format('MM/DD/YYYY hh:mm a');
        }
        else {
            return ' ';
        }
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }

    isExpired = (validDate) => {
        return !moment().isBefore(validDate);
    }

    getUniqueNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    checkHasDigit = (str) => /\d/.test(str);

    formatValidatorError = (validatorError) => {
        const errors = {};

        _.map(validatorError, (error) => {
            errors[error.field] = error.message;
        });

        return errors;
    };

    getGuid = () => uuid();

    copyTextMessage = (value) => navigator.clipboard.writeText(value);

    separateUppercaseLetter = (value) => value.match(/[A-Z][a-z]+/g).join(' ');

    requestNotificationPermission() {
        return new Promise((resolve, reject) => {
            if ('Notification' in window) {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        toastAction.info('Permission granted!');
                        resolve();
                    } else {
                        reject(new Error('Notification permission denied'));
                    }
                });
            } else {
                reject(new Error('Notification API not supported'));
            }
        });
    }

    async displayNotification(title, options = {}) {
        if ('Notification' in window && Notification.permission === 'granted') {
            const notification = new Notification(title, options);
            return notification;
        } else {
            await this.requestNotificationPermission();
        }
    }

    // Date Helper Function 
    formatDate = (date, format) => {
        return moment(new Date(date)).format(format);
    }

    emailDate = () => moment().format('DD-MM-YYYY');

    getTodayDate = () => moment();

    getCurrentMonthStartDate = () => moment().clone().startOf('month');

    getCurrentWeekStartDate = () => moment().clone().startOf('isoWeek');

    isOwnDB = (tenant) => {
        if (!tenant?.vehicleCollection) {
            return false;
        }

        if (!tenant?.vehicleCollection?.currentDB) {
            return false;
        }

        return true;
    }

    isSharedData = (tenantId) => {
        if (!tenantId) {
            return false;
        }

        if (!tenantId.settings) {
            return false;
        }

        return tenantId.settings.isSharedData;
    }
    // Date Helper Function
}

export default new UtilsHelper();
