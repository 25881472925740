import loadingGif from '../../asset/loading-gif.gif'

const Loading = () => {

    return (
        <div className='absolute flex bg-white h-screen w-screen justify-center items-center'>
            <div className='flex flex-col w-auto h-auto justify-center items-center'>
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900 "></div>
                <img src={loadingGif} alt="Loading..." className=' w-28' />
            </div>
        </div>
    )
}

export default Loading