import * as actionType from './types';
import ApiService, { ApiUrls } from '../../service/service.client';
import utils from '../../common/utils';
import storageService from '../../service/storageService';
import { storageKey } from '../../common/constant/constant.all';

// =========== Admin ================
export const setUserData = (user) => {
    return {
        type: actionType.SET_USER_DATA,
        payload: user
    }
}

export const clearReduxState = () => {
    return {
        type: actionType.CLEAR_REDUX_STATE
    }
}

export const setIsVerified = (isVerified) => {
    return {
        type: actionType.IS_VERIFIED,
        payload: isVerified
    }
}

// =========== Seizer ==================
export const setSeizerList = (seizerList) => {
    return {
        type: actionType.SET_SEIZER_LIST,
        payload: seizerList
    }
}

export const removeOneSeizer = (_id) => {
    return {
        type: actionType.REMOVE_ONE_SEIZER,
        payload: _id
    }
}

export const addOneSeizer = (seizer) => {
    return {
        type: actionType.ADD_ONE_SEIZER,
        payload: seizer
    }
}

// ============ Email =====================
export const setTextFormats = (payload) => {
    return {
        type: actionType.SET_TEXT_FORMATS,
        payload
    }
}

// ============ UploadFile =================
export const setFileList = (fileList) => {
    return {
        type: actionType.SET_FILE_LIST,
        payload: fileList
    }
}

export const removeOneFile = (id) => {
    return {
        type: actionType.REMOVE_ONE_UPLOAD_FILE,
        payload: id
    }
}

export const addOneFile = (uploadFile) => {
    return {
        type: actionType.ADD_ONE_UPLOAD_FILE,
        payload: uploadFile
    }
}

// ============= Finance ===================
export const setFinanceList = (financeList) => {
    return {
        type: actionType.SET_FINANCE_LIST,
        payload: financeList
    }
}

export const addOneFinance = (finance) => {
    return {
        type: actionType.ADD_ONE_FINANCE,
        payload: finance
    }
}

export const removeOneFinance = (_id) => {
    return {
        type: actionType.REMOVE_ONE_FINANCE,
        payload: _id
    }
}

// ==================== accessToken ===============
export const addAccessToken = (token) => {
    return {
        type: actionType.ADD_ACCESS_TOKEN,
        payload: token
    }
}

// ============ Total Record Response =================
export const setTotalAndDeleteCount = (record) => {
    return {
        type: actionType.TOTAL_RECORD,
        payload: record
    }
}

export const updateTotalVehicleCount = (totalVehicleCount) => {
    return {
        type: actionType.UPDATE_VEHICLE_COUNT,
        payload: totalVehicleCount
    }
}

export const updateDeleteVehicleCount = (deleteVehicleCount) => {
    return {
        type: actionType.UPDATE_DELETE_VEHICLE_COUNT,
        payload: deleteVehicleCount
    }
}

// ============ Socket =================
export const initializeSocketConnection = () => {
    return {
        type: actionType.INITIALIZE_SOCKET,
    }
}

export const initializeQrCodeConnection = (uid) => {
    return {
        type: actionType.INITIALIZE_QR_CONNECTION,
        payload: uid
    }
}

export const initializeNotificationConnection = (agencyId) => {
    return {
        type: actionType.SOCKET_NOTIFICATION_INITIALIZE,
        payload: agencyId
    }
}

export const addNotification = (payload) => {
    return {
        type: actionType.ADD_NOTIFICATION,
        payload
    }
}

export const removeNotification = (_id) => {
    return {
        type: actionType.REMOVE_NOTIFICATION,
        payload: _id
    }
}

export const clearNotification = () => {
    return {
        type: actionType.CLEAR_NOTIFICATION
    }
}

export const loginWithUid = (uid) => {
    return async function (dispatch) {
        const { data } = await ApiService.get(utils.formatString(ApiUrls.loginWithGuid, uid));
        if (data) {
            storageService.setJsonItem(storageKey.userDetail, data);
            const { adminDetail, accessToken } = data;
            dispatch({
                type: actionType.LOGIN_WITH_UID_TYPE,
                payload: {
                    adminDetail,
                    accessToken
                }
            });
        }
    }
}

export const onWebRefresh = (payload) => {
    return {
        type: actionType.WEB_REFRESH,
        payload
    }
}

export const onSetRequests = (payload) => {
    return {
        type: actionType.SET_REQUESTS,
        payload
    }
}

export const setUsers = (payload) => {
    return {
        type: actionType.SET_USERS,
        payload
    }
}

export const setVehicle = (payload) => {
    return {
        type: actionType.SET_VEHICLE,
        payload
    }
}
