export const storageKey = {
    user: 'user',
    refreshToken: 'rt',
    emailCredential: 'verifiedEmail',
    suggestionList: 'suggestionList',
    userDetail: 'userDetail',
    storedEmail: 'storedEmail',
    psoNameKey: 'PsoKey',
    districtKey: 'DistrictKey',
    locationListKey: 'LocationListKey',
    accessToken: 'accessToken',
}

export const strings = {
    errorMessage: 'We Facing Some Problem, Please Try Again Later !'
}

export const firebaseKeys = {
    webRefresh: 'webRefresh',
    confirmListRefresh: 0,
    refreshWithoutNotification: 1
}

export const repoStatus = {
    unknown: 'unknown',
    confirm: 'confirm',
    release: 'release',
    cancel: 'cancel'
}

export const vehicleType = {
    all: 'All',
    twoWheeler: '2W',
    threeWheeler: '3W',
    fourWheelerAndMore: '4WAndMore'
};

export const roles = {
    admin: 'Admin',
    user: 'User',
    superAdmin: 'SuperAdmin'
};

export default {
    vehicleSeries: [
        "ALL",
        "AP",
        "AR",
        "AS",
        "BR",
        "CG",
        "DL",
        "GA",
        "GJ",
        "HR",
        "HP",
        "JK",
        "JH",
        "KA",
        "KL",
        "MP",
        "MH",
        "MN",
        "ML",
        "MZ",
        "NL",
        "OR",
        "PB",
        "RJ",
        "SK",
        "TN",
        "TG",
        "TR",
        "UP",
        "UT",
        "WB",
        "CH",
        "DN",
        "LD",
        "LA",
        "PY"
    ]
};
