import * as actionType from './types'

const initialize = {
    user: null,
    totalRecord: 0,
    deleteVehicleCount: 0,
    seizerList: [],
    textFormats: [],
    financeList: [],
    fileList: [],
    isVerified: false,
    accessToken: null,
    notifications: [],
    webRefresh: {
        webChanges: -1,
        refresh: ''
    },
    requests: [],
    users: [],
    vehicleState: {
        vehicles: [],
        searchOption: 0,
        series: 'ALL'
    },
};

const myState = (state = initialize, action) => {
    switch (action.type) {
        case actionType.SET_USER_DATA:
            return {
                ...state,
                user: action.payload
            };
        case actionType.CLEAR_REDUX_STATE:
            return {
                ...state,
                user: null,
                seizerList: [],
                textFormats: [],
                financeList: [],
                fileList: [],
                accessToken: null,
            }
        case actionType.IS_VERIFIED:
            return {
                ...state,
                isVerified: action.payload
            }
        //  ========= Seizer ==========
        case actionType.SET_SEIZER_LIST:
            return {
                ...state,
                seizerList: action.payload
            };
        case actionType.REMOVE_ONE_SEIZER:
            return {
                ...state,
                seizerList: state.seizerList.filter(seizer => seizer._id != action.payload)
            };
        case actionType.ADD_ONE_SEIZER:
            return {
                ...state,
                seizerList: [...state.seizerList, action.payload]
            };

        // ========= Email =============
        case actionType.SET_TEXT_FORMATS:
            return {
                ...state,
                textFormats: action.payload
            };

        // ======== UploadFile ==========
        case actionType.SET_FILE_LIST:
            return {
                ...state,
                fileList: action.payload
            }
        case actionType.REMOVE_ONE_UPLOAD_FILE:
            return {
                ...state,
                fileList: state.fileList.filter((file) => file._id != action.payload)
            }
        case actionType.ADD_ONE_UPLOAD_FILE:
            return {
                ...state,
                fileList: [...state.fileList, action.payload]
            }

        // ======== Finance ==============
        case actionType.SET_FINANCE_LIST:
            return {
                ...state,
                financeList: action.payload
            }
        case actionType.ADD_ONE_FINANCE:
            return {
                ...state,
                financeList: [...state.financeList, action.payload]
            }
        case actionType.REMOVE_ONE_FINANCE:
            return {
                ...state,
                financeList: state.financeList.filter(fin => fin._id != action.payload)
            }
        // AccessToken
        case actionType.ADD_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case actionType.LOGIN_WITH_UID_TYPE:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                user: action.payload.adminDetail
            }
        // Total Record and delete count
        case actionType.TOTAL_RECORD:
            return {
                ...state,
                totalRecord: action.payload.totalRecord,
                deleteVehicleCount: action.payload.deleteVehicleCount
            }
        case actionType.UPDATE_VEHICLE_COUNT:
            return {
                ...state,
                totalRecord: action.payload
            }
        case actionType.UPDATE_DELETE_VEHICLE_COUNT:
            return {
                ...state,
                deleteVehicleCount: (state.deleteVehicleCount + action.payload)
            }
        // NOTIFICATION
        case actionType.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            }
        case actionType.CLEAR_NOTIFICATION:
            return {
                ...state,
                notifications: []
            }
        case actionType.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((note) => note._id !== action.payload),
            }
        // web refresh
        case actionType.WEB_REFRESH:
            return {
                ...state,
                webRefresh: action.payload
            }
        case actionType.SET_REQUESTS:
            return {
                ...state,
                requests: action.payload
            }
        // users
        case actionType.SET_USERS:
            return {
                ...state,
                users: action.payload
            }
        // Vehicle
        case actionType.SET_VEHICLE:
            return {
                ...state,
                vehicleState: {
                    ...state.vehicleState,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default myState