import utils from "../common/utils"
import { storageKey } from '../common/constant/constant.all'

const setUserData = (userDetail) => {
    localStorage.setItem(storageKey.user, utils.toBase64(JSON.stringify(userDetail)))
}

const getUserData = () => {
    let user = utils.fromBase64(localStorage.getItem(storageKey.user))
    if (user) return user;
    return null
}

const setRefreshToken = (rToken) => {
    localStorage.setItem(storageKey.refreshToken, rToken)
}

const setItem = (key, value) => {
    localStorage.setItem(key, value)
}

const getItem = (key) => {
    let item = localStorage.getItem(key)
    if (item) return item
    return null
}

// ============ function for JSON and Array storage ===========
const setJsonItem = (key, json) => {
    localStorage.setItem(key, JSON.stringify(json))
}

const getJsonItem = (key) => {
    let itemJson = localStorage.getItem(key);
    if (itemJson) return JSON.parse(itemJson);
    return null;
}

const removeJsonItem = (key) => {
    return localStorage.removeItem(key);
}
// ============ function for JSON and Array storage ===========

export default {
    setUserData,
    setRefreshToken,
    setItem,
    getItem,
    getUserData,
    setJsonItem,
    getJsonItem,
    removeJsonItem
}