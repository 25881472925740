import { getDatabase, onValue, ref } from 'firebase/database';
import { jwtDecode } from "jwt-decode";
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { firebaseKeys, storageKey } from './common/constant/constant.all';
import Routes from './common/router';
import utils from './common/utils';
import Loading from './component/common.component/loading';
import Toastify, { toastAction } from './component/common.component/toastify';
import { addAccessToken, initializeNotificationConnection, onSetRequests, onWebRefresh, setFinanceList, setUserData } from './reducer/state/action';
import firebaseApp from './service/firebaseService';
import ApiService, { ApiUrls } from './service/service.client';
import storageService from './service/storageService';

function App() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isFirebaseRealInit, setIsFirebaseRealInit] = useState(false);
  const { user, webRefresh, accessToken } = useSelector((state) => state.stateReducer);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (accessToken) {
      getUserDetail();
    }
  }, [accessToken]);

  useEffect(() => {
    if (user) {
      listenFirebaseSocket();
      getAppInitData();
    }
  }, [user]);

  const getUserDetail = async () => {
    const decodedToken = jwtDecode(accessToken);

    const { data, error } = await ApiService.get(utils.formatString(ApiUrls.getWebUserById, decodedToken?.userId));
    if (data) {
      dispatch(initializeNotificationConnection(data.user._id));
      dispatch(setUserData(data.user));
    } else {
      toastAction.error(error);
    }
    setIsLoading(false);
  }

  const getAppInitData = async () => {
    Promise.all([
      ApiService.get(ApiUrls.getFinanceList),
      // ApiService.get(ApiUrls.getUsers)
    ]).then((results) => {
      if (results[0] && results[0].data) {
        dispatch(setFinanceList(results[0].data?.finances || []));
      }

      // if (results[1] && results[1].data) {
      //   dispatch(setUsers(results[1].data?.users || []));
      // }
    });
  }

  const listenFirebaseSocket = async () => {
    if (!isFirebaseRealInit) {
      await getConfirmRequest();
      setIsFirebaseRealInit(true);
    }

    const db = getDatabase(firebaseApp);
    const refresh = ref(db, `${user.companyName}/${firebaseKeys.webRefresh}`);
    onValue(refresh, async (snapshot) => {
      const refresh = snapshot.val();
      if (refresh && refresh.refresh && refresh.refresh !== webRefresh.refresh) {
        if (refresh.webChanges === firebaseKeys.confirmListRefresh || refresh.webChanges === firebaseKeys.refreshWithoutNotification) {
          await getConfirmRequest(refresh.webChanges);
        }
        dispatch(onWebRefresh({ ...refresh, webChanges: -1 }));
      }
    });
  }

  const getConfirmRequest = async (code) => {
    const { data } = await ApiService.get(ApiUrls.getConfirmRequest);
    if (data) {
      if (data.requests && data.requests.length > 0 && code === firebaseKeys.confirmListRefresh) {
        utils.displayNotification('Found new request check it in home page.');
      }
      dispatch(onSetRequests(data.requests));
    }
  }

  const init = () => {
    const accessToken = storageService.getJsonItem(storageKey.accessToken);
    if (!_.isNull(accessToken)) {
      dispatch(addAccessToken(accessToken));
    } else {
      setIsLoading(false);
    }
  }


  return (
    <>
      {
        isLoading ? <Loading /> : <Routes />
      }
      <Toastify />
    </>
  );
}

export default App;